import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { format, parseISO } from 'date-fns';
import * as EmailValidator from 'email-validator';

import Loader from '../../tools/Loader';

class DetailStep extends Component {
  static propTypes = {
    bookStep: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,

    taxiType: PropTypes.string.isRequired,
    addressA: PropTypes.string.isRequired,
    addressB: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    returnDate: PropTypes.object.isRequired,
    wantsReturn: PropTypes.bool.isRequired,
  }

  state = {
    fullName: '',
    email: '',
    amountPerson: 1,
    phoneNumber: '',
    remark: '',
    message: '',
    isLoading: false
  }

  goToBook() {
    const { fullName, email, phoneNumber, amountPerson, remark } = this.state;
    const { bookStep } = this.props;

    if (fullName === '') {
      this.setState({
        messageSuccess: false,
        message: 'Voer een volledige naam in'
      });

      return;
    }

    if (phoneNumber.length < 4) {
      this.setState({
        messageSuccess: false,
        message: 'Voer een geldige telefoonnummer in'
      });

      return;
    }

    if (EmailValidator.validate(email)) {
      this.setState({ isLoading: true });

      bookStep(fullName, email, phoneNumber, amountPerson, remark);
    } else {
      this.setState({
        messageSuccess: false,
        message: 'Voer een geldige email in'
      });
    }

  }

  renderMessage() {
    const { message, messageSuccess } = this.state;

    return (
      <div className={`alert alert-${messageSuccess ? 'success' : 'danger'}`} role="alert">
        {message}
      </div>
    );
  }

  render() {
    const { price, taxiType, addressA, addressB, date, wantsReturn, returnDate} = this.props;
    const { message, isLoading } = this.state;

    if (isLoading) {
      return (
        <Loader />
      );
    }

    return (
      <div className="customer-details">
        <div className="modal-body">
          <div className="row">
            <div className="col-12 col-md-6 summary-container">
              <div className="summary">
                <div className="text-center">
                  {taxiType === 'car' ?
                    [
                      <img alt="car" src="https://img.icons8.com/color/96/000000/taxi.png" />,
                      <b>Auto</b>
                    ]
                    :
                    [
                      <img alt="bus" src="https://img.icons8.com/color/96/000000/bus2.png" />,
                      <b>Busje</b>
                    ]
                  }
                </div>
                <hr />

                <p>
                  <b>Van: </b>
                  {addressA}
                </p>
                <p>
                  <b>Naar: </b>
                  {addressB}
                </p>
                <p>
                  <b>Datum: </b>
                  {format(date, 'dd-MM-yyyy, HH:mm')}
                </p>

                { wantsReturn &&
                  <p>
                    <b>Retour Datum: </b>
                    {format(returnDate, 'dd-MM-yyyy, HH:mm')}
                  </p>
                }

                {/* <div className="price">
                  <b>€ {price},-</b>
                </div> */}

                <button className="btn btn-primary" onClick={() => this.props.previousStep()}>
                  Wijzig
                </button>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form">
                {message && this.renderMessage()}
                <p><b className="your-info">Uw Gegevens</b></p>

                <div className="form-group">
                  <i className="fas fa-user"></i>

                  <input type="name" className="form-control" onChange={n => this.setState({ fullName: n.target.value })} placeholder="Volledige naam (verplicht)" />
                </div>

                <div className="form-group">
                  <i className="fas fa-envelope"></i>

                  <input type="email" className="form-control" onChange={e => this.setState({ email: e.target.value })} placeholder="E-mail (verplicht)" />
                </div>

                <div className="form-group">
                  <i className="fas fa-phone fa-rotate-90"></i>

                  <input type="tel" className="form-control" onChange={e => this.setState({ phoneNumber: e.target.value })} placeholder="Telefoonnummer (verplicht)" />
                </div>

                <div className="form-group">
                  <i className="fas fa-users"></i>

                  <input type="number" className="form-control" onChange={e => this.setState({ amountPerson: e.target.value })} placeholder="Aantal personen" />
                </div>


                <div className="form-group">
                  <label>Moeten we nog wat weten?</label>
                  <textarea className="form-control" onChange={e => this.setState({ remark: e.target.value })} placeholder="Opmerking" />
                </div>

                <button className="btn btn-primary book-taxi-button" onClick={() => this.goToBook()}>
                  Bevestig boeking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailStep;
