import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fetchPOST } from '../../tools/fetch';

import { appUrl } from '../../tools/settings';
import Loader from '../../tools/Loader';

class PriceStep extends Component {
  static propTypes = {
    setTaxiPrice: PropTypes.func.isRequired,
    togglePopUp: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    date: PropTypes.object.isRequired,
    addressA: PropTypes.string.isRequired,
    addressB: PropTypes.string.isRequired,
    wantsReturn: PropTypes.bool.isRequired
  }

  state = {
    isLoading: true,
    fixedPrice: false,
    carPrice: 0,
    busPrice: 0
  }

  componentDidMount() {
    const { addressA, addressB, date, wantsReturn, togglePopUp, setMessage } = this.props;

    fetchPOST(`${appUrl}/price`, {
      address_a: addressA,
      address_b: addressB,
      has_return: wantsReturn,
      date: date
    }, {
      stateContext: this,
      onSuccess: (json) => {
        // this.calculatePrice(json);
        this.setState({
          carPrice: json.car_price,
          busPrice: json.bus_price,
          fixedPrice: json.fixed_price
        });
      },
      onError: (error) => {
        if (error == 'low price') {
          setMessage({ success: false, message: 'Sorry, de rit is te kort neem contact op met centrale' });
          togglePopUp();
        } else {
          setMessage({ success: false, message: error });
          togglePopUp();
        }
      }
    });
  }

  onSelected(type, price) {
    this.props.setTaxiPrice(type, price);
  }

  renderFixedPrice() {
    return (
      <li className="fixed-price">
        Vaste Tarief
      </li>
    );
  }

  render() {
    const { carPrice, busPrice, fixedPrice, isLoading } = this.state;

    if (isLoading) {
      return (
        <Loader />
      );
    }

    return (
      <div>
        <div className="modal-body">
          <div className="container-fluid">

            <div className="row">
              <div className="col-12 col-md-6 taxi-type-container">
                <div className="taxi-type">
                  <img alt="car" src="https://img.icons8.com/color/96/000000/taxi.png" />
                  <p><b>Auto</b></p>

                  <ul>
                    <li>Tot 4 personen</li>
                    <li>Genoeg ruimte</li>
                    {fixedPrice && this.renderFixedPrice()}
                  </ul>

                  <p className="price">Prijs: € {carPrice},-</p>

                  <button className="btn btn-primary car-type-select" onClick={() => this.onSelected('car', carPrice)}>Selecteer</button>
                </div>
              </div>

              <div className="col-12 col-md-6 taxi-type-container">
                <div className="taxi-type">
                  <img alt="bus" src="https://img.icons8.com/color/96/000000/bus2.png" />
                  <p><b>Busje</b></p>

                  <ul>
                    <li>Tot 8 personen</li>
                    <li>Meer ruimte voor baggage</li>
                    {fixedPrice && this.renderFixedPrice()}
                  </ul>

                  <p className="price">Prijs: € {busPrice},-</p>

                  <button className="btn btn-primary bus-type-select" onClick={() => this.onSelected('bus', busPrice)}>Selecteer</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default PriceStep;
