import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import { fetchPOST } from '../tools/fetch';

import PriceStep from './popup/PriceStep';
import DetailStep from './popup/DetailStep';
import ConfirmationStep from './popup/ConfirmationStep';

import { appUrl } from '../tools/settings';
import LuggageStep from './popup/LuggageStep';

class PopUp extends Component {
  static propTypes = {
    togglePopUp: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,

    addressA: PropTypes.string.isRequired,
    addressB: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    returnDate: PropTypes.object.isRequired,
    wantsReturn: PropTypes.bool.isRequired,
  }

  state = {
    step: 0,
    taxiType: '',
    luggage: {},
    price: 0
  }

  setLuggage = (luggage) => {
    this.setState({
      luggage,
      step: this.state.step + 1
    });
  }

  setTaxiPrice = (taxiType, price) => {
    this.setState({
      taxiType,
      price,
      step: this.state.step + 1
    });
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    });
  }

  previousStep = () => {
    this.setState({
      step: this.state.step - 1
    });
  }

  goToCustomerDetails = (price) => {
    this.setState({
      price,
      step: this.state.step + 1
    });
  }

  goToConfirmationStep = () => {
    this.setState({
      step: this.state.step + 1
    });
  }

  bookStep = (fullName, email, phoneNumber, amountPerson, remark) => {
    const { addressA, addressB, date, togglePopUp, setMessage, returnDate, wantsReturn } = this.props;
    const { taxiType, luggage, price } = this.state;

    fetchPOST(`${appUrl}/booking`, {
      full_name: fullName,
      email,
      phone: phoneNumber,
      car_type: taxiType,
      luggage,
      address_a: addressA,
      address_b: addressB,
      amount_person: amountPerson,
      date: format(date, 'dd-MM-yyyy, HH:mm'),
      price,
      remark,
      has_return: wantsReturn,
      return_date: wantsReturn ? format(returnDate, 'dd-MM-yyyy, HH:mm') : null,
    }, {
      stateContext: this,
      onSuccess: (json) => {
        this.goToConfirmationStep();
      },
      onError: (error) => {
        setMessage({ success: false, message: 'Sorry, er is wat fout gegaan probeer het later opnieuw' });
        togglePopUp();
      }
    });
  }

  renderContent() {
    const { step } = this.state;

    if (step === 0) {
      return (
        <PriceStep
          addressA={this.props.addressA}
          addressB={this.props.addressB}
	  date={this.props.date}
          wantsReturn={this.props.wantsReturn}
          setTaxiPrice={this.setTaxiPrice}
          setMessage={this.props.setMessage}
          togglePopUp={this.props.togglePopUp}
        />
      );
    } else if (step === 1) {
      return (
        <LuggageStep
          luggage={this.state.luggage}
          previousStep={this.previousStep}
          setLuggage={this.setLuggage}
        />
      );
    } else if (step === 2) {
      return (
        <DetailStep
          addressA={this.props.addressA}
          addressB={this.props.addressB}
          date={this.props.date}
          returnDate={this.props.returnDate}
          wantsReturn={this.props.wantsReturn}
          taxiType={this.state.taxiType}

          price={this.state.price}
          bookStep={this.bookStep}
          previousStep={this.previousStep}
        />
      );
    }

    return (
      <ConfirmationStep />
    );
  }

  render() {
    const { step } = this.state;
    const { togglePopUp } = this.props;

    return (
      <div className="popup">
        <div className="modal-backdrop in" style={{ opacity: '0.6' }}></div>

        <div className="modal fade show" aria-labelledby="myModalLabel" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="steps">
                  <div className="steps-row setup-panel">
                    <div className="step">
                      <span className={step === 0 ? 'current' : undefined}>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                    <div className="step">
                      <span className={step === 1 ? 'current' : undefined}>
                        <i className="fas fa-suitcase"></i>
                      </span>
                    </div>
                    <div className="step">
                      <span className={(step === 2 || step === 3) ? 'current' : undefined}>
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <a role="button" tabIndex="0" className="close" onClick={togglePopUp}>
                  <span aria-hidden="true">&times;</span>
                </a>
              </div>
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopUp;
