import React, { Component } from 'react';

class ConfirmationStep extends Component {

  render() {
    return (
      <div className="modal-body">
        <div className="container-fluid">
          <div className="confirmation-step">
            <img alt="Happy" src="https://img.icons8.com/officel/80/000000/lol.png" />
            <h2>Bedankt voor uw boeking</h2>
            <p>We hebben u een bevestigingsmail gestuurd. U kunt betalen in de taxi, er is mogelijkheid tot pinnen of cash voor meer informatie kunt u gerust ons mailen of bellen.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationStep;
