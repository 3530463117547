export const getCSRFToken = () => document.head.querySelector("[name='csrf-token']").content;

export const postUpload = (url, formData, dispatchers) => {
  const {
    stateContext, onBeforeFetch, onSuccess, onError, onCompleted
  } = dispatchers;

  const hasStateContext = stateContext && stateContext.state !== null;

  const dispatchError = (message) => {
    if (onError) { onError(message); }

    if (hasStateContext) {
      stateContext.setState({ error: message });
    }
  };

  const stateContextUploadingCallback = (isUploading = false, uploadProgress = 0) => {
    if (hasStateContext && stateContext.state.isUploading !== null) {
      stateContext.setState({
        isUploading,
        uploadProgress,
        error: null
      });
    }
  };

  stateContextUploadingCallback(true);
  if (onBeforeFetch) { onBeforeFetch(); }

  $.ajax({
    xhr: () => {
      const xhr = new window.XMLHttpRequest();

      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          let percentComplete = evt.loaded / evt.total;
          percentComplete = parseInt(percentComplete * 100, 10);

          stateContextUploadingCallback(true, percentComplete);
        }
      }, false);

      return xhr;
    },
    url,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    beforeSend: (xhr) => {
      stateContextUploadingCallback();

      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
    },
    error: (xhr, status, errorThrown) => {
      console.error(e); // eslint-disable-line

      stateContextUploadingCallback(false);

      dispatchError(errorThrown);

      if (onCompleted) { onCompleted(); }
    },
    complete: (xhr, status) => {
      stateContextUploadingCallback(false);

      if (status === 'success') {
        const json = $.parseJSON(xhr.responseText);

        if (onSuccess) { onSuccess(json); }
      }
    }
  });
};

export const fetchHTTP = (url, method, data, dispatchers) => {
  const {
    stateContext, onBeforeFetch, onSuccess, onError, onCompleted
  } = dispatchers;

  const hasStateContext = stateContext && stateContext.state !== null;

  const dispatchError = (message) => {
    if (onError) { onError(message); }

    if (hasStateContext) {
      stateContext.setState({ error: message });
    }
  };

  const stateContextLoadingCallback = (loading) => {
    const hash = { isLoading: loading };

    if (loading) {
      // Reset any error (if needed)
      hash.error = null;
    }

    if (hasStateContext && stateContext.state.isLoading !== null) {
      stateContext.setState(hash);
    }

    if (hasStateContext && stateContext.state.isSaving !== null) {
      stateContext.setState(hash);
    }
  };

  stateContextLoadingCallback(true);
  if (onBeforeFetch) { onBeforeFetch(); }

  // Perform request
  fetch(url, {
    credentials: 'same-origin',
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': getCSRFToken()
    },
    body: method === 'GET' ? undefined : JSON.stringify(data)
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    })
    .then((json) => {
      if (json.success) {
        if (onSuccess) { onSuccess(json); }
      } else {
        dispatchError(json.message);
      }

      stateContextLoadingCallback(false);
      if (onCompleted) { onCompleted(); }
    })
    .catch((e) => {
      console.error(e); // eslint-disable-line

      stateContextLoadingCallback(false);

      dispatchError(e.message);

      if (onCompleted) { onCompleted(); }
    });
};

export const fetchGET = (url, dispatchers) => {
  fetchHTTP(url, 'GET', {}, dispatchers);
};

export const fetchPOST = (url, data, dispatchers) => {
  fetchHTTP(url, 'POST', data, dispatchers);
};

export const fetchPUT = (url, data, dispatchers) => {
  fetchHTTP(url, 'PUT', data, dispatchers);
};

export const fetchPATCH = (url, data, dispatchers) => {
  fetchHTTP(url, 'PATCH', data, dispatchers);
};

export const fetchDELETE = (url, dispatchers) => {
  fetchHTTP(url, 'DELETE', {}, dispatchers);
};
