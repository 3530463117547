import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import AutoComplete from './components/AutoComplete';
import PopUp from './PopUp';

class Booking extends Component {
  state = {
    date: '',
    returnDate: '',
    addressFullA: '',
    addressFullB: '',
    addressA: '',
    addressB: '',
    popup: false,
    wantsReturn: false,
    messageSuccess: '',
    message: ''
  }

  setMessage = (data) => {
    this.setState({ messageSuccess: data.success, message: data.message });
  }

  changeAddressA = (addressA, houseNumber) => {
    let addressFullA = addressA;

    if (houseNumber) {
      const splitAddress = addressA.split(',');
      splitAddress[0] = `${splitAddress[0]} ${houseNumber}`;

      addressFullA = splitAddress.join(',');
    }

    this.setState({ addressA, addressFullA });
  }

  changeAddressB = (addressB, houseNumber) => {
    let addressFullB = addressB;

    if (houseNumber) {
      const splitAddress = addressB.split(',');
      splitAddress[0] = `${splitAddress[0]} ${houseNumber}`;

      addressFullB = splitAddress.join(',');
    }

    this.setState({ addressB, addressFullB });
  }

  changeDate = (date) => {
    this.setState({ date });
  }

  changeReturnDate = (returnDate) => {
    this.setState({ returnDate });
  }

  switchAddresses(e) {
    e.preventDefault();

    this.setState({
      addressA: this.state.addressB,
      addressB: this.state.addressA
    });
  }

  togglePopup = () => {
    this.setState({ popup: !this.state.popup });
  }

  renderMessage() {
    const { message, messageSuccess } = this.state;

    return (
      <div className={`alert alert-${messageSuccess ? 'success' : 'danger'}`} role="alert">
        {message}
      </div>
    );
  }

  render() {
    const { date, returnDate, popup, addressA, addressB, addressFullA, addressFullB, wantsReturn, message } = this.state;

    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    };

    if (popup) {

      return (
        <PopUp
          togglePopUp={this.togglePopup}
          setMessage={this.setMessage}
          addressA={addressFullA}
          addressB={addressFullB}
          date={date}
          returnDate={returnDate}
          wantsReturn={wantsReturn} />
      );
    };

    return (
      <div className="booking-box">
        {message && this.renderMessage()}

        <form>
          {window.location.pathname === '/boeking' ? (
            <h1 className="text-center" style={{ fontSize: 25 }}>Wilt u een <span>Taxi boeken?</span></h1>
          ) : (
            <h3 className="text-center">Wilt u een <span>Taxi boeken?</span></h3>
          )}

          <button className="btn btn-primary switch-button" onClick={e => this.switchAddresses(e)}>
            <i className="fa fa-exchange fa-rotate-90" aria-hidden="true" />
          </button>

          <div className="address-group">
            <div className="form-group">
              <i className="fas fa-map-marker"></i>

              <AutoComplete type="Ophaaladres" address={addressA} handleSelect={this.changeAddressA} />
            </div>

            <div className="form-group">
              <i className="fas fa-map-marker-alt"></i>

              <AutoComplete type="Bestemmings adres" address={addressB} handleSelect={this.changeAddressB} />
            </div>
          </div>

          <hr />

          <div className="form-group">
            <div className="date">
              <label>Wanneer moeten we u ophalen?</label>
              <i className="fas fa-calendar-day"></i>
              <DatePicker
                selected={date}
                onChange={this.changeDate}
                placeholderText="Datum"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd MMMM, HH:mm"
                timeCaption="time"
                minDate={new Date()}
                filterTime={filterPassedTime}
                className="form-control"
	    	excludeDates={[new Date(2023, 0, 1), new Date(2022, 11, 31)]}
              />
            </div>

            {wantsReturn &&
              <div className="date mt-2">
                <i className="fas fa-calendar-day date-icon-2"></i>
                <DatePicker
                  selected={returnDate}
                  onChange={this.changeReturnDate}
                  placeholderText="Retour Datum"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd MMMM, HH:mm"
                  timeCaption="time"
                  minDate={date}
                  filterTime={filterPassedTime}
                  className="form-control"
		  excludeDates={[new Date(2023, 0, 1), new Date(2022, 11, 31)]}
                />
              </div>
            }

            <div className="checkbox">
              <input type="checkbox" name="returnDate" onClick={() => this.setState({ wantsReturn: !wantsReturn })} />
              <label htmlFor="returnDate">Ik wil retour boeken</label>
            </div>
          </div>

          <a role="button" tabIndex="0" className={`calculate-price btn btn-primary ${(addressA.length < 2 || addressB.length < 2 || date === '') && 'disabled'}`} onClick={() => this.togglePopup()} >
            Bereken prijs
          </a>
        </form>
      </div>
    );
  }
}

export default Booking;
