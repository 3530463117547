import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CounterInput from 'react-counter-input';
import Loader from '../../tools/Loader';

class LuggageStep extends Component {
  static propTypes = {
    luggage: PropTypes.object.isRequired,

    setLuggage: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
  }

  state = {
    hasLuggage: false,
    isLoading: true,
    smallLuggage: 0,
    midLuggage: 0,
    largeLuggage: 0
  }

  componentWillMount() {
    const { luggage } = this.props;

    if (luggage == null) {
      return this.setState({
        isLoading: false
      });
    }

    return this.setState({
      smallLuggage: luggage.smallLuggage,
      midLuggage: luggage.midLuggage,
      largeLuggage: luggage.largeLuggage,
      hasLuggage: (luggage.smallLuggage != null || luggage.midLuggage != null || luggage.largeLuggage != null),
      isLoading: false
    });
  }

  nextStep() {
    const { smallLuggage, midLuggage, largeLuggage } = this.state;
    const luggage = {
      smallLuggage,
      midLuggage,
      largeLuggage
    };

    this.props.setLuggage(luggage);
  }

  toggleLuggage(bool) {
    if (!bool) {
      this.nextStep();
    }

    this.setState({
      hasLuggage: bool
    });
  }

  renderLuggageOptions() {
    const { smallLuggage, midLuggage, largeLuggage } = this.state;

    return [
      <hr />,

      <div className="row luggage">
        <div className="col-12 col-md-4">
          <img alt="koffer" src="/images/grote-koffer.png" />
          <p>Grote Koffer</p>

          <div className="counter mx-auto">
            <CounterInput
              min={0}
              max={10}
              count={largeLuggage}
              onCountChange={count => this.setState({ largeLuggage: count})}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <img alt="kleine-koffer" src="https://img.icons8.com/color/96/000000/suitcase.png" />
          <p>Kleine Koffer</p>

          <div className="counter mx-auto">
            <CounterInput
              min={0}
              max={10}
              count={midLuggage}
              onCountChange={count => this.setState({ midLuggage: count})}
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <img alt="rugzak" src="https://img.icons8.com/color/96/000000/school-backpack.png" />
          <p>Handbagage/Rugzak</p>

          <div className="counter mx-auto">
            <CounterInput
              min={0}
              max={10}
              count={smallLuggage}
              onCountChange={count => this.setState({ smallLuggage: count})}
            />
          </div>
        </div>
      </div>
    ];
  }

  render() {
    const { hasLuggage, isLoading } = this.state;

    if (isLoading) {
      return (<Loader />);
    }

    return (
      <div className="luggage-step">
        <h2>Heeft u baggage?</h2>

        <button className={`btn btn-primary no-luggage ${hasLuggage && 'active'}`} onClick={() => this.toggleLuggage(true)}>Ja</button>
        <button className={`btn btn-primary yes-luggage ${!hasLuggage && 'active'}`} onClick={() => this.toggleLuggage(false)}>Nee</button>

        {hasLuggage && this.renderLuggageOptions()}
        <hr />

        <div className="steps">
          <button className="btn btn-primary float-left" onClick={() => this.props.previousStep()}>Vorige Stap</button>
          <button className="btn btn-primary float-right step-to-confirmation" onClick={() => this.nextStep()}>Volgende Stap</button>
        </div>
      </div>
    );
  }
}

export default LuggageStep;
