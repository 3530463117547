import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PlacesAutocomplete from 'react-places-autocomplete';

class AutoComplete extends Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleSelect: PropTypes.func.isRequired,
  }

  state = {
    hasHousenumber: true,
    houseNumber: '',
    isStreet: false,
  }

  setHouseNumber = (houseNumber) => {
    this.setState({ houseNumber });

    this.checkHasHouseNumber(this.props.address, houseNumber);
  }

  checkHasHouseNumber = (address, houseNumberNew) => {
    const { isStreet } = this.state;
    let { houseNumber } = this.state;

    if (houseNumberNew != null) {
      houseNumber = houseNumberNew;
    }
    const hasHousenumber = ((address.substring(3)).match('.*\\d.*') && address !== '');

    this.setState({hasHousenumber});

    this.props.handleSelect(address, (!hasHousenumber && isStreet) ? houseNumber : null);
  }

  checkPlaceType = (address, placeId, suggestion) => {

    this.setState({ isStreet: suggestion.types.includes('route') });
    this.checkHasHouseNumber(address);
  }

  render() {
    const { type, address } = this.props;
    const { hasHousenumber, isStreet, houseNumber } = this.state;

    const searchOptions = {
      componentRestrictions: {country: ['nl'] }
    };

    return (
      <div className="dropdown">
        <PlacesAutocomplete
          value={address}
          onChange={this.checkHasHouseNumber}
          onSelect={this.checkPlaceType}
          searchOptions={searchOptions}
          shouldFetchSuggestions={address.length > 1}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className={`${(!hasHousenumber && isStreet) && 'set-display-inline'}`}>
              <input
                {...getInputProps({
                  placeholder: type,
                  className: 'form-control'
                })}
              />
              <div className="dropdown-menu" style={suggestions.length > 0 ? { display: 'block'} : {}}>
                {suggestions.map((suggestion) => {
                  const className = suggestion.active ? 'dropdown-item active' : 'dropdown-item';

                  return (
                    <div {...getSuggestionItemProps(suggestion, { className })}>
                      <span>
                        <p><b>{suggestion.formattedSuggestion.mainText}</b></p>
                        <p>{suggestion.formattedSuggestion.secondaryText}</p>
                        <hr />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {(!hasHousenumber && isStreet) && <input type="text" value={houseNumber} onChange={e => this.setHouseNumber(e.target.value)} className="form-control house-no" placeholder="Huis nr." />}
      </div>
    );
  }
}

export default AutoComplete;
