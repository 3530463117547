import React, { Component } from 'react';

class Loader extends Component {

  render() {
    return (
      <div className="loader"></div>
    );
  }
}

export default Loader;
